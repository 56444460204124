/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'
import * as Sentry from "@sentry/vue";

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'

const app = createApp(App)

registerPlugins(app)

if (process.env.NODE_ENV !== 'production') {
    app.config.devtools = true;
    app.config.debug = true;
    app.config.silent = false;  // This ensures all warnings and logs are printed to the console.
}


app.config.errorHandler = (err, instance, info) => {
    console.error('Global Vue Error Handler:', err);
    console.error('Component instance:', instance);
    console.error('Error info:', info);
    // Optionally send errors to a logging service
};



app.mount('#app')



